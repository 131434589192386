import {
	type FuturesMarketAsset,
	OrderTypeEnum,
	PerennialArbNetworkIds,
	type PerennialFuturesMarket,
	type PerpsMarketV2,
	type PerpsMarketV3,
	PerpsProvider,
	PositionSide,
	SnxV2NetworkIds,
	SnxV3NetworkIds,
} from '@kwenta/sdk/types'
import { chainToV3Provider } from '@kwenta/sdk/utils'
import { wei } from '@kwenta/wei'
import { createSelector } from '@reduxjs/toolkit'

import { selectOffchainPricesInfo, selectPrices } from 'state/prices/selectors'
import type { RootState } from 'state/store'
import { selectSignerNetwork, selectWallet } from 'state/wallet/selectors'
import {
	crossMarginProvider,
	isolatedMarginProvider,
	providerIsCrossMargin,
	unserializePerennialMarkets,
	unserializeTradeInputs,
	unserializeV2Markets,
	unserializeV3Market,
} from 'utils/futures'

import type { IsolatedMarginAccountData } from '../isolatedMargin/types'
import type { SnxV3AccountData } from '../snxPerpsV3/types'
import { TradingModes } from '../types'

import type { MarkPriceInfos, MarkPrices } from './types'

export const selectPerpsProvider = (state: RootState) => state.futures.preferences.selectedProvider
export const selectIsolatedMarginProvider = (state: RootState) =>
	isolatedMarginProvider(state.futures.preferences.selectedProvider)
export const selectCrossMarginProvider = (state: RootState) =>
	crossMarginProvider(state.futures.preferences.selectedProvider)

export const selectTradingMode = createSelector(
	(state: RootState) => state.futures.preferences,
	(preferences) => preferences.tradingMode
)

export const selectUnlimitedApproval = createSelector(
	(state: RootState) => state.futures.preferences,
	(preferences) =>
		preferences.unlimitedApproval || preferences.tradingMode === TradingModes.ONE_CLICK
)

export const selectLeverageSide = (state: RootState) => state.futures.tradePanel.leverageSide

export const selectLeverageInput = (state: RootState) => state.futures.tradePanel.leverageInput

export const selectOneClickTradingSelected = createSelector(
	selectTradingMode,
	(mode) => mode === TradingModes.ONE_CLICK
)

export const selectMarketAsset = createSelector(
	selectPerpsProvider,
	(state: RootState) => state.futures,
	(provider, futures) => futures.selectedMarketAsset[provider]
)

export const selectMarketIndexPrice = createSelector(
	selectMarketAsset,
	selectPrices,
	(marketAsset, prices) => {
		const price = prices[marketAsset]
		return price?.offChain ?? price?.onChain ?? wei(0)
	}
)

export const selectMarketOnchainPrice = createSelector(
	selectMarketAsset,
	selectPrices,
	(marketAsset, prices) => {
		const price = prices[marketAsset]
		return price?.onChain ?? wei(0)
	}
)

export const selectMarketPriceInfo = createSelector(
	selectMarketAsset,
	selectOffchainPricesInfo,
	(asset, pricesInfo) => {
		if (!asset || !pricesInfo[asset]) return
		return pricesInfo[asset]
	}
)

export const selectSelectedSubAccountAddress = (state: RootState) =>
	state.futures.delegation.selectedSubAccountAddress

export const selectAbstractionEthBalance = (state: RootState) =>
	state.futures.oneClickTrading.ethBalance

export const selectAbstractionUsdcBalance = (state: RootState) =>
	state.futures.oneClickTrading.usdcBalance

export const selectAbstractionUsdcEngineAllowance = (state: RootState) =>
	state.futures.oneClickTrading.usdcEngineAllowance

export const selectUserInfoCollapsed = (state: RootState) =>
	state.futures.preferences.userInfoCollapsed

export const selectUserInfoShowAllMarkets = (state: RootState) =>
	state.futures.preferences.userInfoShowAllMarkets
export const selectEditPositionInputs = (state: RootState) => state.futures.editPositionInputs

export const selectOpenMarketDropdownList = (state: RootState) =>
	state.app.showModal?.type === 'manage_markets'

export const selectPortfolioChartType = (state: RootState) =>
	state.futures.preferences.portfolioChartType

export const selectSelectedPortfolioTimeframe = (state: RootState) =>
	state.futures.dashboard.selectedPortfolioTimeframe

export const selectRawTradePanelInputs = (state: RootState) => state.futures.tradePanel.inputs

export const selectTradeOrderType = createSelector(selectRawTradePanelInputs, (inputs) => {
	return inputs.orderType
})

export const selectTradePanelSlTpInputs = createSelector(
	selectRawTradePanelInputs,
	({ stopLossPrice, takeProfitPrice }) => ({
		stopLossPrice: stopLossPrice || '',
		takeProfitPrice: takeProfitPrice || '',
		stopLossPriceWei: stopLossPrice && stopLossPrice !== '' ? wei(stopLossPrice) : undefined,
		takeProfitPriceWei:
			takeProfitPrice && takeProfitPrice !== '' ? wei(takeProfitPrice) : undefined,
	})
)

export const selectTradePanelInputs = createSelector(
	selectLeverageSide,
	selectRawTradePanelInputs,
	(side, tradeInputs) => {
		const inputs = unserializeTradeInputs(tradeInputs)
		const deltas = {
			susdSizeDelta: side === PositionSide.LONG ? inputs.susdSize : inputs.susdSize.neg(),
			nativeSizeDelta: side === PositionSide.LONG ? inputs.nativeSize : inputs.nativeSize.neg(),
		}
		return {
			...inputs,
			...deltas,
			susdSizeString: tradeInputs.susdSize.toString(),
			nativeSizeString: tradeInputs.nativeSize.toString(),
		}
	}
)

export const selectSlTpTradeInputs = createSelector(
	selectRawTradePanelInputs,
	({ stopLossPrice, takeProfitPrice }) => ({
		stopLossPrice: stopLossPrice || '',
		takeProfitPrice: takeProfitPrice || '',
		stopLossPriceWei: stopLossPrice && stopLossPrice !== '' ? wei(stopLossPrice) : undefined,
		takeProfitPriceWei:
			takeProfitPrice && takeProfitPrice !== '' ? wei(takeProfitPrice) : undefined,
	})
)

export const selectIsConditionalOrder = createSelector(
	selectTradePanelInputs,
	({ orderType }) => orderType !== OrderTypeEnum.MARKET
)

export const selectTradePanelOrderPriceInput = createSelector(
	selectRawTradePanelInputs,
	(inputs) => inputs.orderPrice.price ?? ''
)

export const selectTradePanelTradePrice = createSelector(
	selectTradePanelOrderPriceInput,
	selectMarketIndexPrice,
	selectIsConditionalOrder,
	(orderPrice, indexPrice, isConditional) =>
		isConditional ? (Number(orderPrice) > 0 ? wei(orderPrice) : undefined) : indexPrice
)

export const selectClosePositionOrderInputs = createSelector(
	(state: RootState) => state.futures,
	(futures) => {
		return {
			...futures.closePositionOrderInputs,
			isConditional: futures.closePositionOrderInputs.orderType !== OrderTypeEnum.MARKET,
		}
	}
)

export const selectV2Markets = createSelector(
	(state: RootState) => state.futures.providerData.markets,
	(markets) => {
		return unserializeV2Markets(markets[PerpsProvider.SNX_V2_OP] ?? [])
	}
)

export const selectProviderNetworks = createSelector(selectSignerNetwork, (networkId) => {
	const v2NetworkId =
		networkId === SnxV2NetworkIds.OPTIMISM_SEPOLIA
			? SnxV2NetworkIds.OPTIMISM_SEPOLIA
			: SnxV2NetworkIds.OPTIMISM_MAINNET
	const v3NetworkId =
		networkId === SnxV3NetworkIds.BASE_SEPOLIA
			? SnxV3NetworkIds.BASE_SEPOLIA
			: SnxV3NetworkIds.BASE_MAINNET
	const perennialNetworkId =
		networkId === PerennialArbNetworkIds.ARB_SEPOLIA
			? PerennialArbNetworkIds.ARB_SEPOLIA
			: PerennialArbNetworkIds.ARB_MAINNET
	const snxArbNetworkId =
		networkId === SnxV3NetworkIds.ARB_SEPOLIA
			? SnxV3NetworkIds.ARB_SEPOLIA
			: // TODO: Include mainnet
				SnxV3NetworkIds.ARB_SEPOLIA
	return {
		[PerpsProvider.SNX_V2_OP]: v2NetworkId,
		[PerpsProvider.SNX_V3_BASE]: v3NetworkId,
		[PerpsProvider.PERENNIAL_V2_ARB]: perennialNetworkId,
		[PerpsProvider.SNX_V3_ARB]: snxArbNetworkId,
	}
})

export const selectSnxPerpsV2Network = createSelector(
	selectProviderNetworks,
	(networks) => networks[PerpsProvider.SNX_V2_OP]
)

export const selectSnxV3BaseNetwork = createSelector(
	selectProviderNetworks,
	(networks) => networks[PerpsProvider.SNX_V3_BASE]
)

export const selectSnxV3ArbNetwork = createSelector(
	selectProviderNetworks,
	(networks) => networks[PerpsProvider.SNX_V3_ARB]
)

export const selectPerennialV2Network = createSelector(
	selectProviderNetworks,
	(networks) => networks[PerpsProvider.PERENNIAL_V2_ARB]
)

export const selectProviderNetwork = createSelector(
	selectPerpsProvider,
	selectProviderNetworks,
	(provider, networks) => networks[provider]
)

export const selectSnxV3Network = createSelector(
	selectPerpsProvider,
	selectSnxV3BaseNetwork,
	selectSnxV3ArbNetwork,
	(provider, base, arb) =>
		(provider === PerpsProvider.SNX_V3_BASE ? base : arb) ?? SnxV3NetworkIds.BASE_MAINNET
)

export const selectSnxV3Provider = createSelector(selectSnxV3Network, (network) =>
	chainToV3Provider(network)
)

export const selectV3Markets = createSelector(
	selectSnxV3Provider,
	(state: RootState) => state.futures.providerData.markets,
	(provider, markets) => {
		const v3Markets = markets[provider] ?? []
		return v3Markets.map(unserializeV3Market)
	}
)

export const selectMarkets = createSelector(
	selectPerpsProvider,
	(state: RootState) => state.futures.providerData,
	(provider, data) => {
		const markets = data.markets[provider] ?? []
		switch (provider) {
			case PerpsProvider.SNX_V2_OP:
				return unserializeV2Markets(markets as PerpsMarketV2<string>[])
			case PerpsProvider.SNX_V3_BASE:
			case PerpsProvider.SNX_V3_ARB:
				return (markets as PerpsMarketV3<string>[]).map(unserializeV3Market)
			case PerpsProvider.PERENNIAL_V2_ARB:
				return unserializePerennialMarkets(markets as PerennialFuturesMarket<string>[])
			default:
				return []
		}
	}
)

export const selectMarketsByProvider = createSelector(
	(state: RootState) => state.futures.providerData,
	(data) => {
		return {
			[PerpsProvider.SNX_V2_OP]: unserializeV2Markets(
				data.markets[PerpsProvider.SNX_V2_OP] ?? ([] as PerpsMarketV2<string>[])
			),
			[PerpsProvider.SNX_V3_BASE]: (
				data.markets[PerpsProvider.SNX_V3_BASE] ?? ([] as PerpsMarketV3<string>[])
			).map(unserializeV3Market),
			[PerpsProvider.SNX_V3_ARB]: (
				data.markets[PerpsProvider.SNX_V3_ARB] ?? ([] as PerpsMarketV3<string>[])
			).map(unserializeV3Market),
			[PerpsProvider.PERENNIAL_V2_ARB]: unserializePerennialMarkets(
				data.markets[PerpsProvider.PERENNIAL_V2_ARB] ?? ([] as PerennialFuturesMarket<string>[])
			),
		}
	}
)

export const selectAccountData = createSelector(
	selectPerpsProvider,
	selectProviderNetworks,
	selectWallet,
	(state: RootState) => state.futures.accounts,
	(type, networks, wallet, accounts) => {
		const accountData = wallet ? accounts?.[type]?.[wallet] : undefined
		let data = undefined
		switch (type) {
			case PerpsProvider.PERENNIAL_V2_ARB:
			case PerpsProvider.SNX_V2_OP:
				data = accountData as IsolatedMarginAccountData
				break
			case PerpsProvider.SNX_V3_ARB:
			case PerpsProvider.SNX_V3_BASE:
				data = accountData as SnxV3AccountData
				break

			default:
				return
		}
		const providerNetwork = networks[type]
		if (data && data.network !== providerNetwork) {
			return undefined
		}
		return data
	}
)

export const selectIsolatedMarginAccountData = createSelector(selectAccountData, (accountData) => {
	if (!accountData?.provider || providerIsCrossMargin(accountData?.provider)) return
	return accountData as IsolatedMarginAccountData
})

export const selectSelectedMarketAssetsByProvider = (state: RootState) =>
	Object.keys(state.futures.selectedMarketAsset).reduce(
		(acc, provider) => {
			const asset = state.futures.selectedMarketAsset[provider as PerpsProvider]
			if (asset) {
				acc[provider as PerpsProvider] = asset
			}
			return acc
		},
		{} as Record<PerpsProvider, FuturesMarketAsset>
	)

export const selectCurrentMarketInfos = createSelector(
	selectMarketsByProvider,
	selectSelectedMarketAssetsByProvider,
	(markets, selectedMarketAssets) => {
		return Object.keys(markets).reduce(
			(acc, provider) => {
				const perpsProvider = provider as PerpsProvider
				const market = markets[perpsProvider].find(
					(m) => m.asset === selectedMarketAssets[perpsProvider]
				)
				if (market) {
					acc[perpsProvider] = market
				}
				return acc
			},
			{} as Partial<Record<PerpsProvider, PerpsMarketV3 | PerpsMarketV2 | PerennialFuturesMarket>>
		)
	}
)

export const selectMarketInfo = createSelector(
	selectPerpsProvider,
	selectCurrentMarketInfos,
	(type, markets) => markets[type]
)

export const selectMarkPrices = createSelector(selectMarkets, selectPrices, (markets, prices) => {
	const markPrices: MarkPrices = {}
	return markets.reduce((acc, market) => {
		const price = prices[market.asset]?.offChain ?? wei(0)
		const skewedPrice =
			market.provider === PerpsProvider.PERENNIAL_V2_ARB
				? price
				: wei(price).mul(wei(market.marketSkew).div(market.settings.skewScale).add(1))
		acc[market.asset] = skewedPrice
		return acc
	}, markPrices)
})

export const selectAllMarkPriceInfos = createSelector(
	selectOffchainPricesInfo,
	(state: RootState) => state.futures.providerData.markets,
	(prices, markets) => {
		const marketInfos = (
			markets: (PerpsMarketV2<string> | PerpsMarketV3<string> | PerennialFuturesMarket<string>)[]
		) => {
			return markets.reduce<MarkPriceInfos>((acc, market) => {
				const price = prices[market.asset]?.price ?? wei(0)
				const skewedPrice =
					market.provider === PerpsProvider.PERENNIAL_V2_ARB
						? price
						: wei(price).mul(wei(market.marketSkew).div(market.settings.skewScale).add(1))

				acc[market.asset] = {
					price: skewedPrice,
					change: prices[market.asset]?.change ?? null,
				}
				return acc
			}, {})
		}
		return {
			[PerpsProvider.SNX_V2_OP]: marketInfos(markets[PerpsProvider.SNX_V2_OP]),
			[PerpsProvider.SNX_V3_BASE]: marketInfos(markets[PerpsProvider.SNX_V3_BASE]),
			[PerpsProvider.PERENNIAL_V2_ARB]: marketInfos(markets[PerpsProvider.PERENNIAL_V2_ARB]),
			[PerpsProvider.SNX_V3_ARB]: marketInfos(markets[PerpsProvider.SNX_V3_ARB]),
		}
	}
)

export const selectMarkPriceInfos = createSelector(
	selectPerpsProvider,
	selectAllMarkPriceInfos,
	(type, priceInfos) => {
		return priceInfos[type]
	}
)

export const selectSkewAdjustedPriceInfo = createSelector(
	selectMarketPriceInfo,
	selectMarketInfo,
	(priceInfo, marketInfo) => {
		if (
			marketInfo?.provider === PerpsProvider.PERENNIAL_V2_ARB ||
			!marketInfo?.marketSkew ||
			!marketInfo?.settings.skewScale
		)
			return priceInfo
		return priceInfo
			? {
					price: wei(priceInfo.price).mul(
						wei(marketInfo.marketSkew).div(marketInfo.settings.skewScale).add(1)
					),
					change: priceInfo?.change,
				}
			: undefined
	}
)

export const selectEditPositionModalMarket = (state: RootState) =>
	state.app.showPositionModal?.marketAsset

export const selectEditCOModalInputs = createSelector(
	(state: RootState) => state.futures.editConditionalOrderInputs,
	(inputs) => ({
		size: inputs.size ?? '',
		orderPrice: inputs.orderPrice,
		margin: inputs.margin ?? '',
	})
)

export const selectIsolatedMaxLeverage = createSelector(selectMarketInfo, (market) => {
	const adjustedMaxLeverage = market?.appMaxLeverage ?? wei(1)
	return adjustedMaxLeverage
})
export const selectSlTpModalInputs = createSelector(
	(state: RootState) => state.futures.sltpModalInputs,
	(inputs) => ({
		stopLossPrice: inputs.stopLossPrice ?? '',
		takeProfitPrice: inputs.takeProfitPrice ?? '',
	})
)
