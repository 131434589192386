import type { AsyncThunkAction } from '@reduxjs/toolkit'
import { useQuery } from '@tanstack/react-query'
import { type TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux'

import Connector from 'containers/Connector'

import type { AppDispatch, AppThunk, RootState } from './store'

export const useAppDispatch: () => AppDispatch = useDispatch
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector

const DEFAULT_REFETCH_INTERVAL = 20000

type ActionType = AsyncThunkAction<any, any, any> | AppThunk<any>

// Helper function to serialize dependencies
const serialize = (value: any): string => {
	if (typeof value === 'bigint') {
		return value.toString()
	}
	return JSON.stringify(value)
}

// Helper function to generate query key
const getQueryKey = (name: string, dependencies: any[] = []) => {
	const actionName = name || 'unknownAction'
	return [actionName, ...dependencies.map(serialize)]
}

export const useFetchAction = (
	action: () => AsyncThunkAction<any, any, any>,
	options?: {
		dependencies?: any[]
		disabled?: boolean
		staleTime?: number
		cacheTime?: number
		name?: string
	}
) => {
	const { providerReady } = Connector.useContainer()
	const dispatch = useDispatch<AppDispatch>()
	const queryKey = getQueryKey(options?.name || action.name, options?.dependencies)

	useQuery({
		queryKey,
		queryFn: async () => dispatch(action()),
		enabled: !options?.disabled && providerReady,
		staleTime: options?.staleTime,
		refetchOnWindowFocus: false,
	})
}

export const usePollAction = (
	actionName: string,
	action: () => ActionType,
	options?: {
		dependencies?: any[]
		disabled?: boolean
		intervalTime?: number
	}
) => {
	const { providerReady } = Connector.useContainer()
	const dispatch = useDispatch<AppDispatch>()
	const queryKey = getQueryKey(actionName, options?.dependencies)

	useQuery({
		queryKey,
		queryFn: async () => dispatch(action()),
		enabled: providerReady && !options?.disabled,
		refetchInterval: options?.intervalTime || DEFAULT_REFETCH_INTERVAL,
	})
}
