import React from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

import ErrorIcon from 'assets/svg/app/error.svg'
import SpinnerIcon from 'assets/svg/app/spinner.svg'
import SuccessIcon from 'assets/svg/app/success.svg'
import { FlexDivCol, FlexDivRowCentered } from 'components/layout/flex'

import { Body } from './Text'

type NotificationProps = {
	closeToast?: VoidFunction
	failureReason?: string
}

const NotificationPending = () => {
	const { t } = useTranslation()

	return (
		<FlexDivCol rowGap="10px">
			<FlexDivRowCentered justifyContent="flex-start" columnGap="5px">
				<SpinnerIcon width={12} height={12} />
				{t('common.transaction.notification-title.pending')}
			</FlexDivRowCentered>
			<StyledBody>{t('common.transaction.transaction-sent')}</StyledBody>
		</FlexDivCol>
	)
}

const NotificationSuccess = () => {
	const { t } = useTranslation()

	return (
		<FlexDivCol rowGap="10px">
			<FlexDivRowCentered justifyContent="flex-start" columnGap="5px">
				<SuccessIcon width={12} height={12} />
				{t('common.transaction.notification-title.success')}
			</FlexDivRowCentered>
			<StyledBody>{t('common.transaction.transaction-confirmed')}</StyledBody>
		</FlexDivCol>
	)
}

const NotificationError = ({ failureReason }: NotificationProps) => {
	const { t } = useTranslation()

	return (
		<FlexDivCol rowGap="10px">
			<FlexDivRowCentered justifyContent="flex-start" columnGap="5px">
				<ErrorIcon width={12} height={12} />
				{t('common.transaction.notification-title.error')}
			</FlexDivRowCentered>
			<StyledBody>
				<div>{t('common.transaction.transaction-failed')}</div>
				<div>{failureReason}</div>
			</StyledBody>
		</FlexDivCol>
	)
}

const StyledBody = styled(Body)`
	font-size: 13px;
	color: ${(props) => props.theme.colors.selectedTheme.newTheme.banner.default.text};
`

export { NotificationPending, NotificationSuccess, NotificationError }
