import type {
	FuturesMarketAsset,
	FuturesOrderHistory,
	FuturesOrderTypeDisplay,
	FuturesTrade,
	FuturesVolumes,
	MarginTransfer,
	NetworkId,
	OrderTypeEnum,
	PerennialFuturesMarket,
	PerpsMarketV2,
	PerpsMarketV3,
	PerpsProvider,
	PerpsV2Position,
	PerpsV3Position,
	PositionSide,
	PotentialTradeStatus,
} from '@kwenta/sdk/types'
import type Wei from '@kwenta/wei'
import type { Address } from 'viem'

import type { PricesInfo } from 'state/prices/types'

import type { DelegationAccountInfo } from '../types'

export type FuturesAccountData<Position, ConditionalOrder> = {
	network: NetworkId
	account: string
	walletAddress: Address
	marketMarginTransfers?: MarginTransfer[]
	positions?: Position[]
	trades: FuturesTrade<string>[]
	conditionalOrders: ConditionalOrder[]
	orderHistory?: FuturesOrderHistory[]
	tradesByPosition: Record<string, FuturesTrade<string>[]>
	delegates?: Array<DelegationAccountInfo>
	subAccounts?: Array<DelegationAccountInfo>
}

export type DelayedOrderWithDetails<T = Wei> = {
	account: string
	marketAddress: string
	market: string
	asset: FuturesMarketAsset
	size: T
	commitDeposit: T
	keeperDeposit: T
	submittedAtTimestamp: number
	executableAtTimestamp: number
	isOffchain: boolean
	desiredFillPrice: T
	targetRoundId: T | null
	orderType: FuturesOrderTypeDisplay
	side: PositionSide
	isStale?: boolean
	isExecutable?: boolean
	isCancelling?: boolean
}

export type FuturesTradePreviewDetails<T = Wei> = {
	action: 'trade' | 'close' | 'edit'
	marketAsset: FuturesMarketAsset
	newSize: T
	sizeDelta: T
	liqPrice: T
	fillPrice: T
	fee: T
	notionalValue: T
	leverage: number
	side: PositionSide
	status: PotentialTradeStatus
	showStatus?: boolean
	statusMessage?: string
	priceImpact: T
	exceedsPriceProtection: boolean
	settlementFee: T
	isConditional: boolean
}

export type FundingRates = {
	timestamp: number
	fundingRate?: number
	longRate?: number
	shortRate?: number
}[]

export type HistoricalFundingRates = Partial<Record<FuturesMarketAsset, FundingRates>>

export type TradeSizeInputs<T = Wei> = {
	nativeSize: T
	susdSize: T
}

export type TradePanelInputs<T = Wei> = TradeSizeInputs<T> & {
	stopLossPrice?: T
	takeProfitPrice?: T
	marginDelta?: T
	orderType: OrderTypeEnum
	orderPrice: {
		price?: T
		invalidLabel?: string
	}
}

export type EditPositionInputs<T = Wei> = {
	nativeSizeDelta: T
	marginDelta: T
}

export type ClosePositionInputs<T = Wei> = {
	nativeSizeDelta: T
	price?: {
		value?: string | undefined | null
		invalidLabel: string | undefined | null
	}
	orderType: OrderTypeEnum
}

export type PreviewAction = 'edit' | 'trade' | 'close'

export type TradePreviewParams = {
	accountId?: string
	orderPrice?: Wei
	currentIndexPrice: Wei
	sizeDelta: Wei
	action: PreviewAction
	isConditional?: boolean
}

export type SnxPerpsV3TradePreviewParams = TradePreviewParams & {
	provider: PerpsProvider.SNX_V3_BASE | PerpsProvider.SNX_V3_ARB
	market: PerpsMarketV3
	marketPrice: Wei
	indexPrice: Wei
}

export type IsolatedTradePreviewParams = TradePreviewParams & {
	provider: PerpsProvider.SNX_V2_OP | PerpsProvider.PERENNIAL_V2_ARB
	market: PerpsMarketV2 | PerennialFuturesMarket
	marginDelta: Wei
	hasStopLoss?: boolean
	hasTakeProfit?: boolean
}

export type DebouncedPreviewParams = (IsolatedTradePreviewParams | SnxPerpsV3TradePreviewParams) & {
	debounceCount: number
}

export type FundingRate<T = Wei> = {
	asset: FuturesMarketAsset
	fundingTitle: string
	fundingRate: T | null
}

export type MarkPrices<T = Wei> = Partial<Record<FuturesMarketAsset, T>>

export type MarkPriceInfos<T = Wei> = Partial<Record<FuturesMarketAsset, PricesInfo<T>>>

export enum CrossMarginRiskStatus {
	Low = 'low',
	Moderate = 'moderate',
	High = 'high',
}

export type FuturesTransactionType =
	| 'deposit_smart_margin'
	| 'withdraw_isolated_margin'
	| 'approve_cross_margin'
	| 'deposit_cross_margin'
	| 'withdraw_cross_margin'
	| 'close_cross_margin'
	| 'cancel_delayed_isolated'
	| 'execute_delayed_isolated'
	| 'close_smart_margin'
	| 'submit_cross_order'
	| 'submit_isolated_order'
	| 'cancel_cross_margin_order'
	| 'withdraw_keeper_balance'
	| 'deposit_keeper_balance'
	| 'create_cross_margin_account'
	| 'add_delegate'
	| 'remove_delegate'
	| 'delegate_to_smart_margin'
	| 'create_isolated_margin_account'
	| 'permit_margin_engine'
	| 'claim_vip_rewards'

export type FetchedMarketsPayload =
	| { provider: PerpsProvider.SNX_V2_OP; markets: PerpsMarketV2<string>[] }
	| { provider: PerpsProvider.SNX_V3_BASE; markets: PerpsMarketV3<string>[] }
	| { provider: PerpsProvider.SNX_V3_ARB; markets: PerpsMarketV3<string>[] }
	| { provider: PerpsProvider.PERENNIAL_V2_ARB; markets: PerennialFuturesMarket<string>[] }

export type FetchedVolumesPayload = { provider: PerpsProvider; volumes: FuturesVolumes<string> }

export type FetchedGlobalTradeHistoryPayload = {
	provider: PerpsProvider
	trades: FuturesTrade<string>[]
}

export type FetchedTraderPositions =
	| {
			traderAddress: string
			provider: PerpsProvider.SNX_V2_OP
			positions: PerpsV2Position<string>[]
	  }
	| {
			traderAddress: string
			provider: PerpsProvider.SNX_V3_BASE | PerpsProvider.SNX_V3_ARB
			positions: PerpsV3Position<string>[]
	  }
	| {
			traderAddress: string
			provider: PerpsProvider.PERENNIAL_V2_ARB
			positions: PerpsV2Position<string>[]
	  }

export type MarketsDetail = {
	[key: string]: {
		token: string
		name: string
		description: string
		links: {
			[key: string]: string
		}
	}
}

export type MarketsDetailProps = { marketsDetail: MarketsDetail }
