import type { FuturesMarketAsset, PerpsProvider } from '@kwenta/sdk/types'

import type { HistoryTab } from 'sections/dashboard/History/HistoryTabs'
import type { MarketTab } from 'sections/dashboard/Markets/MarketsTab'
import type { PointsTab } from 'sections/dashboard/Points/PointsTabs'

import { EXTERNAL_LINKS } from './links'

const prettyURLsDisabled = !!process.env.NEXT_PUBLIC_DISABLE_PRETTY_URLS

const normalizeRoute = (baseURL: string, path: string, queryParam: string) =>
	prettyURLsDisabled ? `${baseURL}?${queryParam}=${path}` : `${baseURL}/${path}`

export const formatUrl = (route: string, params: Record<string, string>) => {
	return `${route}?${new URLSearchParams(params)}`
}

export const ROUTES = {
	Home: {
		Root: '/',
	},
	Dashboard: {
		Home: '/dashboard',
		Overview: normalizeRoute('/dashboard', 'overview', 'tab'),
		Positions: normalizeRoute('/dashboard', 'positions', 'tab'),
		Markets: (tab: MarketTab) => formatUrl('/dashboard/markets	', { tab }),
		Orders: normalizeRoute('/dashboard', 'orders', 'tab'),
		Balances: normalizeRoute('/dashboard', 'balances', 'tab'),
		Leaderboard: normalizeRoute('/dashboard', 'leaderboard', 'tab'),
		Vip: normalizeRoute('/dashboard', 'vip', 'tab'),
		Pools: normalizeRoute('/dashboard', 'pools', 'tab'),
		History: (tab: HistoryTab) => formatUrl('/dashboard/history', { tab }),
		FAQ: normalizeRoute('/dashboard', 'faq', 'tab'),
		Earn: normalizeRoute('/dashboard', 'earn', 'tab'),
		Points: (tab: PointsTab) => formatUrl('/dashboard/points', { tab }),
		Referrals: normalizeRoute('/dashboard', 'referrals', 'tab'),
		Migrate: normalizeRoute('/dashboard', 'migrate', 'tab'),
		Redeem: normalizeRoute('/dashboard', 'redeem', 'tab'),
		TradingRewards: formatUrl('/dashboard/staking', { tab: 'trading-rewards' }),
	},
	Exchange: {
		Home: '/exchange',
		MarketPair: (baseCurrencyKey: string, quoteCurrencyKey: string) =>
			`/exchange/?quote=${quoteCurrencyKey}&base=${baseCurrencyKey}`,
		Into: (currencyKey: string) => `/exchange/?quote=${currencyKey}`,
	},
	Markets: {
		Home: (perpsProvider: PerpsProvider) =>
			formatUrl('/market', { provider: perpsProvider, asset: 'sETH' }),
		MarketPair: (asset: FuturesMarketAsset | string, provider: PerpsProvider) =>
			formatUrl('/market', { asset, provider }),
		Position: (asset: FuturesMarketAsset, provider: PerpsProvider) =>
			formatUrl('/market', {
				asset,
				provider,
				tab: 'position',
			}),
		Orders: (asset: FuturesMarketAsset, provider: PerpsProvider) =>
			formatUrl('/market', { asset, provider, tab: 'orders' }),
		ConditionalOrders: (asset: FuturesMarketAsset, provider: PerpsProvider) =>
			formatUrl('/market', { asset, provider, tab: 'conditional_orders' }),
		TransactionHistory: (asset: FuturesMarketAsset, provider: PerpsProvider) =>
			formatUrl('/market', { asset, provider, tab: 'transaction_history' }),
		OrderHistory: (asset: FuturesMarketAsset, provider: PerpsProvider) =>
			formatUrl('/market', { asset, provider, tab: 'order_history' }),
		Trades: (asset: FuturesMarketAsset, provider: PerpsProvider) =>
			formatUrl('/market', { asset, provider, tab: 'trades' }),
		Assets: (asset: FuturesMarketAsset, provider: PerpsProvider) =>
			formatUrl('/market', { asset, provider, tab: 'assets' }),
		Transfers: (asset: FuturesMarketAsset, provider: PerpsProvider) =>
			formatUrl('/market', { asset, provider, tab: 'transfers' }),
		Balances: (asset: FuturesMarketAsset, provider: PerpsProvider) =>
			formatUrl('/market', { asset, provider, tab: 'balances' }),
		Watcher: (wallet: string, provider: PerpsProvider) =>
			formatUrl('/market', {
				asset: 'sETH',
				provider,
				wallet,
				tab: 'position',
			}),
	},
	Staking: {
		Home: '/staking',
		TradingRewards: formatUrl('/staking', { tab: 'trading-rewards' }),
	},
	Rewards: {
		Home: '/rewards',
	},
	Stats: {
		Home: '/stats',
	},
	Leaderboard: {
		Trader: (trader: string) => formatUrl('/dashboard/leaderboard', { trader }),
		Competition: (competitionRound: string) =>
			formatUrl('/dashboard/leaderboard', { competitionRound }),
	},
	Referrals: {
		Home: '/referrals',
		nftMint: (asset: FuturesMarketAsset, ref: string) => formatUrl('/market', { asset, ref }),
	},
	Earn: {
		Home: '/earn',
	},
}

export const SUB_MENUS = {
	[ROUTES.Dashboard.Overview]: [
		{ label: 'Overview', link: '/dashboard/overview' },
		{ label: 'Markets', link: '/dashboard/markets' },
		// { label: 'Governance', link: '/governance' },
	],
	[ROUTES.Home.Root]: [
		{ label: 'Overview', link: EXTERNAL_LINKS.Docs.Governance },
		{ label: 'KIPs', link: EXTERNAL_LINKS.Governance.Kips },
	],
}

export const setLastVisited = (baseCurrencyPair: string, provider: PerpsProvider): void => {
	localStorage.setItem('lastVisited', ROUTES.Markets.MarketPair(baseCurrencyPair, provider))
}

export default ROUTES
