import Context from './context';
import CallsService from './services/calls';
import KwentaTokenService from './services/kwentaToken';
import MirrorService from './services/mirror';
import PerennialService from './services/perennial';
import PointsService from './services/points';
import PricesService from './services/prices';
import ReferralsService from './services/referrals';
import SnxPerpsV2Service from './services/snxPerpsV2';
import SnxPerpsV3Service from './services/snxPerpsV3';
import StakingMigrationService from './services/stakingMigration';
import StatsService from './services/stats';
import SystemService from './services/system';
import TokensService from './services/tokens';
import TransactionsService from './services/transactions';
export default class KwentaSDK {
    constructor(context) {
        this.context = new Context(context);
        this.snxPerpsV2 = new SnxPerpsV2Service(this);
        this.prices = new PricesService(this);
        this.tokens = new TokensService(this);
        this.transactions = new TransactionsService(this);
        this.kwentaToken = new KwentaTokenService(this);
        this.stats = new StatsService(this);
        this.snxPerpsV3 = new SnxPerpsV3Service(this);
        this.calls = new CallsService(this);
        this.stakingMigration = new StakingMigrationService(this);
        this.referrals = new ReferralsService(this);
        this.system = new SystemService(this);
        this.perennial = new PerennialService();
        this.mirror = new MirrorService();
        this.points = new PointsService(this);
    }
    setWalletClient(signer) {
        return this.context.setWalletClient(signer);
    }
    setWalletAddress(walletAddress) {
        return this.context.setWalletAddress(walletAddress);
    }
}
export { Context };
